<template>
  <div
    id="competition-type1"
    :class="data.block"
    class="competition h-96px my-1"
  >
    <!-- items -->
    <div>
      <swiper
        ref="gameItem"
        :options="swiperOption"
        class="mySwiper px-4"
      >
        <swiper-slide
          v-for="game in competitions"
          :key="game._id"
          class="game__item"
        >
          <div
            :style="bg[theme]"
            class="game__item--card cursor-pointer d-flex justify-center align-center rounded h-96px px-2"
          >
            <!-- img -->
            <div>
              <v-img
                :src="game.game_logo"
                class="rounded"
                max-height="30"
                max-width="30"
              />
            </div>
            <!-- text -->
            <div class="w-80 caption d-flex align-start flex-column ml-1">
              <div class="time primary--text">
                {{ game.start_datetime }}
              </div>
              <div class="game-name title--text font-weight-bold mt-1 h-40px">
                {{ game.competition_name }}
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    swiperOption: {
      slidesPerView: 1.8,
      spaceBetween: 8,
      centeredSlides: true,
      centeredSlidesBounds: true,
    },
  }),

  // 賽事資料
  computed: {
    ...mapGetters(['baseImgUrl', 'theme']),

    competitions() {
      return this.data.data[this.data.type]
    },

    // item background
    bg() {
      return {
        light: { background: 'rgba(206, 206, 206, 0.12)' },
        dark: { background: 'rgba(0, 0, 0, 0.4)' },
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.competition {
	overflow: hidden;
}

.w-80 {
	width: 80%;
}

.h-96px {
	height: 96px;
}

.h-40px {
	height: 40px;
}
</style>
